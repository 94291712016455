<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                       <ValidationProvider name="Production Date" vid="production_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="production_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.production_date')}}<span class="text-danger">*</span>
                              </template>
                               <b-form-input
                                class="fromDate"
                                v-model="formData.production_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                         <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{$t('warehouse_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="formData.org_id"
                              :options="orgList"
                              id="org_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Accession Number" vid="accession_number" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="accession_number"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.accession_number')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="accession_number"
                              v-model="formData.accession_number"
                              :state="errors[0] ? false : (valid ? true : null)"
                              @change="getDataByAccessionNumberForProduction()"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="GID NO." vid="gid_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="gid_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.gid_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="gid_no"
                                  v-model="formData.gid_no"
                                  @change="getDataByAccessionNumberForProduction()"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-overlay :show="loading">
                        <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Crop Type"  vid="crop_type" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="crop_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('germConfig.cropType')}}
                            </template>
                            <b-form-input
                                id="crop_type"
                                v-model="formData.crop_type"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Crop Name"  vid="crop_name" rules="">
                           <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="crop_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('germConfig.cropName')}}
                            </template>
                            <b-form-input
                                id="crop_name"
                                v-model="formData.crop_name"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Scientific Name" vid="scientific_name" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="scientific_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('germConfig.scientific_name')}}
                            </template>
                            <b-form-input
                                id="scientific_name"
                                v-model="formData.scientific_name"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="English Name" vid="english_name" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="english_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('germConfig.english_name')}}
                            </template>
                            <b-form-input
                                id="english_name"
                                v-model="formData.english_name"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Local Name" vid="local_name" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="local_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('germplasm.local_name')}}
                            </template>
                            <b-form-input
                                id="local_name"
                                v-model="formData.local_name"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Cultivar Name" vid="cultivar_name" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="cultivar_name"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('germplasm.cultivar_name')}}
                                </template>
                                <b-form-input
                                id="cultivar_name"
                                v-model="formData.cultivar_name"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- <b-col lg="6" sm="12">
                            <ValidationProvider name="Characterization Type"  vid="characterization_type" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="characterization_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('germConfig.characterization_type_list')}}
                            </template>
                            <b-form-input
                                id="characterization_type"
                                v-model="formData.characterization_type"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Conservation Type"  vid="conservation_type" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="conservation_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('germConfig.conservation_type')}}
                            </template>
                            <b-form-input
                                id="conservation_type"
                                v-model="formData.conservation_type"
                                readonly
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Characterization Details" vid="characterization_details" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="characterization_details"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('germplasm.characterization_details')}}
                                    </template>
                                    <b-textarea
                                    id="characterization_details"
                                    v-model="formData.characterization_details"
                                    readonly
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col> -->
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Unit" vid="unit" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="unit"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('germplasm.unit') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.unit_id"
                              :options="measurementUnitList"
                              id="unit"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Quantity" vid="quantity" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="quantity"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('procurement.quantity')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="mobile_no"
                            v-model="formData.quantity"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('procurement.remarks')}}
                                </template>
                                <b-form-textarea
                                    id="remarks"
                                    v-model="formData.remarks"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('procurement.remarks_bn')}}
                                </template>
                                <b-form-textarea
                                    id="remarks"
                                    v-model="formData.remarks_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        </b-row>
                    </b-overlay>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { productionInfoStore, productionInfoUpdate, getDataByAccessionNumberForProductionNew } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData.org_id = this.$store.state.dataFilters.orgId
      if (this.id) {
        this.formData = this.getFormData()
      }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        org_id: 0,
        production_date: '',
        characterization_type: '',
        accession_number: '',
        gid_no: '',
        local_name: '',
        cultivar_name: '',
        characterization_details: '',
        characterization_description: '',
        crop_type: '',
        crop_name: '',
        scientific_name: '',
        english_name: '',
        unit_id: 0,
        conservation_type: '',
        quantity: '',
        remarks: '',
        remarks_bn: ''
      }
    }
  },
  computed: {
    loading () {
        return this.$store.state.commonObj.loading
    },
    measurementUnitList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.measurementUnitList.filter(item => item.status === 1)
    },
    CropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.gpCropTypeList.filter(item => item.status === 1)
    },
    gpConservationTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.gpConservationTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      }
  },
  watch: {
    // 'formData.accession_number': function (newVal, oldVal) {
    //     this.getDataByAccessionNumberForProduction(newVal)
    // }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    onRightLogoChange (e) {
      this.upload_right_logo = e.target.files[0]
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${productionInfoUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, productionInfoStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getCropNameList (cropTypeId = null) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.gpCropNameList.filter(item => item.status === 1)
      if (cropTypeId) {
        return cropNameList.filter(union => union.cropTypeId === cropTypeId)
      }
      return cropNameList
    },
    getDataByAccessionNumberForProduction () {
      if (!(this.formData.accession_number && this.formData.gid_no)) {
        return false
      }
      const params = Object.assign({}, {
        accession_number: this.formData.accession_number,
        gid_no: this.formData.gid_no
      })
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      RestApi.getData(seedFertilizerServiceBaseUrl, getDataByAccessionNumberForProductionNew, params).then(response => {
          if (response.success) {
              if (this.$i18n.locale === 'bn') {
                  const formdata = {
                      characterization_type: response.data.characterization_type_bn,
                      accession_number: response.data.accession_number,
                      local_name: response.data.local_name_bn,
                      cultivar_name: response.data.cultivar_name_bn,
                      characterization_details: response.data.characterization_details_bn,
                      characterization_description: response.data.characterization_description_bn,
                      crop_type: response.data.crop_type_bn,
                      crop_name: response.data.crop_name_bn,
                      scientific_name: response.data.scientific_name_bn,
                      english_name: response.data.english_name_bn,
                      conservation_type: response.data.conservation_type_bn
                  }
                  Object.assign(this.formData, formdata)
              } else {
                  Object.assign(this.formData, response.data)
              }
          } else {
              this.formData = {
                  characterization_type: '',
                  accession_number: this.formData.accession_number,
                  local_name: '',
                  cultivar_name: '',
                  characterization_details: '',
                  characterization_description: '',
                  crop_type: '',
                  crop_name: '',
                  scientific_name: '',
                  english_name: '',
                  conservation_type_id: 0
              }
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false })
      })
    }
  }
}
</script>
